import { StateCreator, create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { globalSlice, GlobalSlice } from '@/root/Store/globalSlice';
import { visualizerSlice, VisualizerSlice } from '@/root/Store/visualizerSlice';
import { MergeSlice, mergeSlice } from '@/root/Store/mergeSlice';

/**
 * Zustand Slices Pattern
 * https://github.com/pmndrs/zustand/blob/main/docs/guides/typescript.md#slices-pattern
 */

export type CommonState = {
  global: GlobalSlice;
  visualizer: VisualizerSlice;
  merge: MergeSlice;
};

export type ImmerStateCreator<T> = StateCreator<
  CommonState,
  [['zustand/immer', never], never],
  [],
  T
>;

export const useStore = create<CommonState>()(
  immer(
    devtools((...args) => ({
      // Add Slices here
      global: globalSlice(...args),
      visualizer: visualizerSlice(...args),
      merge: mergeSlice(...args),
    }))
  )
);
