import { MergeStructure } from '@/typescript/interfaces';

export const DEFAULT_PROJECT = 'common';
export const fixedChartWidth = 80;

/**
 * An object containing all the Logs Job Types
 */
export const JOB_TYPES = Object.freeze({
  MERGE: 'logs.merge',
});

export const emptyMergeStructure: MergeStructure = {
  logId: '',
  logDescription: '',
  dataType: 'interval',
  drillholeColumnName: '',
  intervalFromColumnName: '',
  intervalToColumnName: '',
  intervalDepthColumnName: '',
  columnOptions: [],
  columnNames: [],
};

/**
 * Different kinds of Merge Types
 */
export const MergeType = Object.freeze({
  categorical: {
    nearest: 'nearest', // Default
    majority: 'majority',
    weighted: 'weighted',
  },
  numerical: {
    mean: 'mean', // Default
    sum: 'sum',
    std_deviation: 'standard deviation',
  },
});
