import type {} from '@redux-devtools/extension'; // required for devtools typing

import { SignedInUser, Project, DrillHole } from '@/typescript/interfaces';
import { ImmerStateCreator } from '@/hooks/useStore';
import { UploadedLog } from '@/typescript/interfaces';

export interface GlobalSlice {
  user: SignedInUser;
  company: string;
  project: Project;
  isDrawerOpen: boolean;
  /**
   * Boolean to control whether `LogsExplorerComponent` is open or closed
   */
  isLogsExplorerOpen: boolean;
  /**
   * Boolean to control whether `UploadLogsModal` is open or closed
   */
  isImportLogsOpen: boolean;
  redirectPath: string | null;
  isDrillholeWarningModalOpen: boolean;
  /**
   * An array of of local log file uploads anywhere in the app
   */
  uploadedLogs: UploadedLog[];
  /**
   * Stores id of currently active global modal
   */
  modalId: string | null;

  setUser: (user: SignedInUser) => void;
  setCompany: (company: string) => void;
  setProject: (project: Project) => void;
  setIsDrawerOpen: (setter: (isDrawerOpen: boolean) => boolean) => void;
  setIsLogsExplorerOpen: (isLogsExplorerOpen: boolean) => void;
  setIsImportLogsOpen: (isImportLogsOpen: boolean) => void;
  setRedirectPath: (path: string | null) => void;
  setIsDrillholeWarningModalOpen: (
    isDrilloholeWarningModalOpen: boolean
  ) => void;
  setUploadedLogs: (
    setter: (uploadedLogs: UploadedLog[]) => UploadedLog[]
  ) => void;
  setModalId: (modalId: string | null) => void;
}

export const globalSlice: ImmerStateCreator<GlobalSlice> = (set) => ({
  user: {} as SignedInUser,
  company: '',
  project: {} as Project,
  isDrawerOpen: true,
  isLogsExplorerOpen: false,
  isImportLogsOpen: false,
  redirectPath: null,
  isDrillholeWarningModalOpen: false,
  uploadedLogs: [],
  modalId: null,

  setUser: (user: SignedInUser) =>
    set(
      (state) => {
        state.global.user = user;
        state.global.company =
          user.signInUserSession?.idToken.payload['custom:company'] || ''; // default company
      },
      false,
      'global.setUser'
    ),
  setCompany: (company: string) =>
    set(
      (state) => {
        state.global.company = company;
      },
      false,
      'global.setCompany'
    ),
  setProject: (project: Project) =>
    set(
      (state) => {
        state.global.project = project;
      },
      false,
      'global.setProject'
    ),
  setIsDrawerOpen: (setter: (isDrawerOpen: boolean) => boolean) =>
    set(
      (state) => {
        state.global.isDrawerOpen = setter(state.global.isDrawerOpen);
      },
      false,
      'global.setIsDrawerOpen'
    ),
  setIsLogsExplorerOpen: (isLogsExplorerOpen: boolean) =>
    set(
      (state) => {
        state.global.isLogsExplorerOpen = isLogsExplorerOpen;
      },
      false,
      'global.setIsLogsExplorerOpen'
    ),
  setIsImportLogsOpen: (isImportLogsOpen: boolean) =>
    set(
      (state) => {
        state.global.isImportLogsOpen = isImportLogsOpen;
      },
      false,
      'global.setIsImportLogsOpen'
    ),
  setRedirectPath: (path: string | null) =>
    set(
      (state) => {
        state.global.redirectPath = path;
      },
      false,
      'global.setRedirectPath'
    ),
  setIsDrillholeWarningModalOpen: (isDrillholeWarningModalOpen: boolean) =>
    set(
      (state) => {
        state.global.isDrillholeWarningModalOpen = isDrillholeWarningModalOpen;
      },
      false,
      'global.setIsDrillholeWarningModalOpen'
    ),
  setUploadedLogs: (setter: (uploadedLogs: UploadedLog[]) => UploadedLog[]) =>
    set(
      (state) => {
        state.global.uploadedLogs = setter(state.global.uploadedLogs);
      },
      false,
      'global.setUploadedLogs'
    ),
    setModalId: (modalId: string | null) =>
    set(
      (state) => {
        state.global.modalId = modalId;
      },
      false,
      'global.setModalId'
    ),
});
