import { createTheme } from '@mui/material/styles';

const gsTheme = createTheme({
  palette: {
    primary: {
      main: '#004cab',
    },
    secondary: {
      main: '#F0B41C',
    },
    info: {
      main: '#004cab',
    },
  },
  typography: {
    fontFamily: 'Source Sans Pro,sans-serif',
  },
});

export { gsTheme };
