import { Box } from '@mui/material';
import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
  MutationCache,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import toast, { Toaster } from 'react-hot-toast';

import { SignedInUser } from './TypeScript/interfaces';
import { errorHandler } from '@/utils/errorHandler';
import AppScopeLoadingComponent from './Components/AppScopeLoading';
import { useStore } from '@/hooks/useStore';

const AppRouter = lazy(() => import('./Router/router'));

// Create a query client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
  queryCache: new QueryCache({
    onError: (error, { meta }) => {
      errorHandler(error as Error);
      // Show error message
      if (meta?.errorMessage) {
        toast.error(meta?.errorMessage as string);
      }
    },
    onSuccess: (_data, { meta }) => {
      // Show success message
      if (meta?.successMessage) {
        toast.success(meta?.successMessage as string);
      }
    },
  }),
  mutationCache: new MutationCache({
    onError: (error, _variables, _context, mutation) => {
      errorHandler(error as Error);
      // Show error message
      if (mutation.meta?.errorMessage) {
        toast.error(mutation.meta?.errorMessage as string);
      }
    },
    onSuccess: (_data, _variables, _context, mutation) => {
      // Show success message
      if (mutation.meta?.successMessage) {
        toast.success(mutation.meta?.successMessage as string);
      }
    },
  }),
});

interface Props {
  user: SignedInUser;
  signOut: () => void;
}

const App: React.FC<Props> = ({ user, signOut }) => {
  const {
    global: { setUser },
  } = useStore();

  // Set user in state when App initializes
  useEffect(() => {
    setUser(user);
  }, [user]);

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Box sx={{ display: 'flex' }}>
          <Suspense fallback={<AppScopeLoadingComponent />}>
            <AppRouter user={user} signOut={signOut} />
          </Suspense>
        </Box>
        <Toaster position="bottom-right" toastOptions={{ duration: 5000 }} />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </BrowserRouter>
  );
};

export default App;
