import type {} from '@redux-devtools/extension'; // required for devtools typing

import { ImmerStateCreator } from '@/hooks/useStore';
import { DrillHole } from '@/typescript/interfaces';

export interface VisualizerSlice {
  zoom: number;
  /**
   * Minimium depth for the drillhole. This is an automatically computed property and should not be mutated directly.
   */
  readonly minDepth: number;
  /**
   * Maximum depth for the drillhole. This is an automatically computed property and should not be mutated directly.
   */
  readonly maxDepth: number;
  /**
   * An array holding all local maximum and minimum depths from all of the rendered visuals.
   * This is used to re-compute min-max depth whenever visuals are added/removed.
   */
  allDepths: number[];
  drillhole: DrillHole;
  showDrillholeImage: boolean;

  setZoom: (zoom: number) => void;
  setAllDepths: (setter: (allDepths: number[]) => number[]) => void;
  setDrillhole: (drillhole: DrillHole) => void;
  setShowDrillholeImage: (showDrillholeImage: boolean) => void;
}

export const visualizerSlice: ImmerStateCreator<VisualizerSlice> = (set) => ({
  zoom: 50,
  allDepths: [],
  drillhole: {} as DrillHole,
  minDepth: null as unknown as number,
  maxDepth: null as unknown as number,
  showDrillholeImage: false,

  setZoom: (zoom: number) =>
    set(
      (state) => {
        state.visualizer.zoom = zoom;
      },
      false,
      'visualizer.setZoom'
    ),
  setShowDrillholeImage: (showDrillholeImage: boolean) =>
    set(
      (state) => {
        state.visualizer.showDrillholeImage = showDrillholeImage;
      },
      false,
      'visualizer.setShowDrillholeImage'
    ),
  setAllDepths: (setter: (allDepths: number[]) => number[]) =>
    set(
      (state) => {
        state.visualizer.allDepths = setter(state.visualizer.allDepths);
        // Recompute min-max depth & update value
        const newMinDepth = Math.min(...state.visualizer.allDepths);
        const newMaxDepth = Math.max(...state.visualizer.allDepths);
        state.visualizer.minDepth = newMinDepth;
        state.visualizer.maxDepth = newMaxDepth;
      },
      false,
      'visualizer.setAllDepths'
    ),
  setDrillhole: (drillhole: DrillHole) =>
    set(
      (state) => {
        state.visualizer.drillhole = drillhole;
      },
      false,
      'visualizer.setDrillhole'
    ),
});
