import type {} from '@redux-devtools/extension'; // required for devtools typing

import { ImmerStateCreator } from '@/hooks/useStore';
import { MergeStructure } from '@/typescript/interfaces';
import { emptyMergeStructure } from '@/utils/constants';

export interface MergeSlice {
  /**
   * The merge structure that is currenly being edited
   */
  activeStructure: MergeStructure;
  /**
   * A metadata corresponding to the base (reference) structures
   */
  baseStructure: MergeStructure;
  /**
   * A list of metadata corresponding to the support structures
   */
  supportStructures: Array<MergeStructure>;
  /**
   * Boolean to decide whether to show previous jobs panel or not
   */
  isPreviousJobsPanelOpen: boolean;

  setIsPreviousJobsPanelOpen: (
    setter: (isPreviousJobsPanelOpen: boolean) => boolean
  ) => void;
  setActiveStructure: (
    setter: (activeStructure: MergeStructure) => MergeStructure
  ) => void;
  setBaseStructure: (baseStructure: MergeStructure) => void;
  setSupportStructure: (
    setter: (supportStructures: MergeStructure[]) => MergeStructure[]
  ) => void;
}

export const mergeSlice: ImmerStateCreator<MergeSlice> = (set) => ({
  activeStructure: emptyMergeStructure,
  baseStructure: { ...emptyMergeStructure, isBaseStructure: true },
  supportStructures: [],
  isPreviousJobsPanelOpen: true,

  setIsPreviousJobsPanelOpen: (
    setter: (isPreviousJobsPanelOpen: boolean) => boolean
  ) =>
    set(
      (state) => {
        state.merge.isPreviousJobsPanelOpen = setter(
          state.merge.isPreviousJobsPanelOpen
        );
      },
      false,
      'merge.setIsPreviousJobsPanelOpen'
    ),
  setActiveStructure: (
    setter: (activeStructure: MergeStructure) => MergeStructure
  ) =>
    set(
      (state) => {
        state.merge.activeStructure = setter(state.merge.activeStructure);
      },
      false,
      'merge.setActiveStructure'
    ),
  setBaseStructure: (baseStructure: MergeStructure) =>
    set(
      (state) => {
        state.merge.baseStructure = baseStructure;
      },
      false,
      'merge.setBaseStructure'
    ),
  setSupportStructure: (
    setter: (supportStructures: MergeStructure[]) => MergeStructure[]
  ) =>
    set(
      (state) => {
        state.merge.supportStructures = setter(state.merge.supportStructures);
      },
      false,
      'merge.setSupportStructure'
    ),
});
